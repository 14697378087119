import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest, useSafeState } from 'ahooks'
import { Button, Input, List, Radio } from 'antd'

import { useControllerRef } from '@/hooks/useAbortController'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { apiGetRecords, IRecord, IRes } from '../../LocalProductList'
import { LocalProductCell } from '../LocalProductCell'
import { ProductCell } from '../ProductCell'
import { ILocalProductPickProps } from './interface'
import styles from './styles.module.less'

type IReqData = {
  searchType: 'sku' | 'spu' | 'productEnTitle'
  keyword: string
  page: number
  size: number
}

/** 本地产品选择组件 */
export const LocalProductPick = ({
  productType,
  shopProductInfo,
  disablePickSkuIds,
  disablePickBtnText,
  onPick,
}: ILocalProductPickProps) => {
  const { t } = useTranslation()
  const [searchType, setSearchType] = useState<IReqData['searchType']>('sku')
  const [keyword, setKeyword] = useState<IReqData['keyword']>('')
  const [page, setPage] = useState<IReqData['page']>(1)
  const [size, setSize] = useState<IReqData['size']>(DEFAULT_PAGE_SIZE)
  const [pickLoadingId, setPickLoadingId] = useSafeState<IRecord['skuId'] | null>(null)

  const reqData = { searchType, keyword, page, size }
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    params: [param],
    run: getList,
  } = useRequest<IRes, [IReqData]>(
    ({ searchType, keyword, page, size }) => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(
        { productType: productType ?? [1, 3], [searchType]: keyword || undefined, page, size },
        controllerRef.current.signal,
      )
    },
    { defaultParams: [reqData] },
  )

  const handlePick = async (localProduct: IRecord) => {
    try {
      setPickLoadingId(localProduct.skuId)
      await onPick?.({ localProduct })
    } finally {
      setPickLoadingId(null)
    }
  }

  return (
    <>
      <div className={styles.header}>
        {shopProductInfo && (
          <div className={styles.header_left}>
            <ProductCell
              img={shopProductInfo.shopProductImgUrl}
              sku={shopProductInfo.sku}
              skuId={shopProductInfo.skuId}
              title={[shopProductInfo.title, shopProductInfo.skuTitle]}
            />
          </div>
        )}
        <div className={styles.header_right}>
          <Radio.Group
            value={searchType}
            onChange={e => {
              const val = e.target.value
              setSearchType(val)
              if (keyword) {
                setPage(1)
                getList({ ...reqData, searchType: val, page: 1 })
              }
            }}
          >
            <Radio value="sku">SKU</Radio>
            <Radio value="spu">SPU</Radio>
            <Radio value="productEnTitle">{t('LocalProductList.ying-wen-pin-ming')}</Radio>
          </Radio.Group>
          <div>
            <Input.Search
              style={{ width: 400, marginTop: 4 }}
              placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
              enterButton
              allowClear
              onChange={e => setKeyword(e.target.value.trim())}
              onSearch={(val, _e, info) => {
                val = val.trim()
                if (info?.source === 'clear' && !param?.keyword) return
                setPage(1)
                getList({ ...reqData, keyword: val, page: 1 })
              }}
            />
          </div>
        </div>
      </div>

      <List
        className={styles.list}
        loading={loading}
        grid={{ gutter: 20, column: 3 }}
        rowKey="skuId"
        dataSource={data?.records}
        renderItem={item => (
          <List.Item>
            <div className={styles.list_item}>
              <div>
                <LocalProductCell
                  img={item.imgUrl}
                  sku={item.sku}
                  skuId={item.skuId}
                  spu={item.spu}
                  title={item.productEnTitle}
                />
              </div>
              <Button
                type="link"
                loading={pickLoadingId === item.skuId}
                disabled={
                  (!!pickLoadingId && pickLoadingId !== item.skuId) || !!disablePickSkuIds?.includes(item.skuId)
                }
                onClick={() => handlePick(item)}
              >
                {disablePickSkuIds?.includes(item.skuId)
                  ? disablePickBtnText || t('2-common.yi-xuan-zhong')
                  : t('2-common.xuan-ze')}
              </Button>
            </div>
          </List.Item>
        )}
        pagination={{
          ...getDefaultPaginationProps(data?.total || 0),
          current: page,
          pageSize: size,
          onChange: (page, size) => {
            setPage(page)
            setSize(size)
            getList({ ...reqData, page, size })
          },
        }}
      />
    </>
  )
}
