import { produce } from 'immer'
import axios, { AxiosRequestConfig } from 'axios'
import _ from 'lodash'

import { downloadFromServer } from '@/utils/download'
import { ISuccessAndFailResultModalProps } from '../components/SuccessAndFailResultModal'
import { IDetail, IRecord, IReqData, IRes, TFormValues, TRecheckFormValues } from './interface'
import './__mock__'

/** 获取本地产品列表 */
export const apiGetRecords = async (params: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRes> => {
  return axios
    .get('/linkhub_oms/local_product/search', {
      params: {
        ...params,
        productType: params.productType ?? [1, 3],
      },
      signal,
    })
    .then(({ $data }) => ({
      ...$data,
      records: $data?.records || [],
    }))
}

/** 获取供应商本地产品列表 */
export const apiGetRecordsBySupplier = async (sysSupplierId: string, isRecordStock = true): Promise<IRecord[]> => {
  return axios
    .get(`/linkhub_oms/local_product/supplier/${sysSupplierId}`, {
      params: {
        isRecordStock: isRecordStock ? 1 : undefined,
      },
    })
    .then(({ $data }) => $data || [])
}

/** 获取产品详情 */
export const apiGetDetail = async (skuId: string): Promise<IDetail> => {
  return axios.get(`/linkhub_oms/local_product/${skuId}/details`).then(({ $data }) => {
    return produce<IDetail>($data, draft => {
      draft.amountDeclares = draft.amountDeclares || []
      draft.amountDeclares.forEach(d => {
        if (d.declareMethod === 2) {
          d.declareValue = (d.declareValue || 0) * 100 // 前端转换成100
        }
      })
      draft.singleProductInfos = draft.singleProductInfos || []
    })
  })
}

/** 新增或编辑产品，传入 skuId 则视为编辑 */
export const apiAddOrEdit = async (reqData: TFormValues, skuId?: string) => {
  reqData = produce(reqData, draft => {
    draft.amountDeclares.forEach(d => {
      if (d.declareMethod === 2) {
        d.declareValue = (d.declareValue || 0) / 100 // 前端转换成1
      }
    })
    draft.singleProductInfos.forEach(d => {
      Object.assign(
        d,
        _.mapValues(d, (v, k) => {
          if (k === 'skuId' || k === 'nums') return v // 后端只要这两个属性
          return undefined
        }),
      )
    })
  })
  if (skuId) {
    await axios.put(`/linkhub_oms/local_product/${skuId}`, reqData)
  } else {
    await axios.post(`/linkhub_oms/local_product`, reqData)
  }
}

/** 仓库复核（仅仓库侧） */
export const apiRecheck = async (reqData: TRecheckFormValues, skuId: string) => {
  await axios.put(`/linkhub_oms/local_product/recheck/${skuId}`, reqData)
}

/** Excel导入（仅商家侧） */
export const apiImport = async (productType: 1 | 2, file: File): Promise<ISuccessAndFailResultModalProps['result']> => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`/linkhub_oms/local_product/excel/${productType}`, formData).then(res => res.$data)
}

/** 发起加工单 */
export const apiCreateProcessOrder = async (skuId: string, nums: number) => {
  await axios.post(`/linkhub_oms/local_product/create_process_order`, { skuId, nums })
}

/** 加工单费用查询 */
export const apiGetProcessOrderFee = async (skuId: string, nums: number): Promise<number> => {
  return axios.post(`/linkhub_oms/local_product/query_process_order_fee`, { skuId, nums }).then(res => res.$data)
}

export const apiMaterialExport = async (): Promise<void> => {
  await downloadFromServer({
    method: 'get',
    url: '/linkhub_oms/local_product/material/export',
    defaultFilename: 'Packaging Materials',
  })
}
